@import "~typeface-roboto";

.App {
  font-family: "Roboto", sans-serif;
  text-align: center;
  background: black;
  min-height: 100vh;
}
.notSupported {
  display: none;
}

.App-Header {
  opacity: 1;
  color: white;
}

.animatorLoading {
  display: block;
  opacity: 1;
}
.animatorLoading-Hidden {
  display: none;
  opacity: 0;
}

.App-Header-Hidden {
  display: none;
  opacity: 0;
}

.parallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 10vh;
}

.parallaxcontact {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 10vh;
}

.inside-parallax {
  width: 70vw;
  margin: auto;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: black;
}
.fixcornerofparallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax-header {
  margin-bottom: 5vh;
  color: white;
  text-align: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-radius: 25px;
  background: rgba(0, 0, 0, 0.753);
  box-shadow: 0px 10px 20px rgb(0, 0, 0);
}
.parallax-header p {
  margin-left: 1rem;
  margin-right: 1rem;
}

.parallax-header-title {
  padding-bottom: 1rem;
  padding-top: 1rem;
  text-align: center;
}

.Gmap {
  margin: auto;
  width: 60vw;
  height: 50vh;
  margin-bottom: 5rem;
}

@media screen and (min-width: 992px) {
  .Gmap {
    margin: auto;
    width: 30vw;
    height: 50vh;
  }
}

.mapsrow {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
  width: 70vw;
}

.contactholder {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mapscolumn {
  display: flex;
}

.mapsholder {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-info {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  margin: auto;
  width: 70vw;
  padding-bottom: 3rem;
}

.contactnfofox {
  margin-top: 3rem;
  background: rgb(39, 39, 39);
  padding-bottom: 3rem;
}

.contactnfofox h2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.contactnfofox h2::after {
  content: "";
  position: relative;
  display: block;
  width: 12rem;
  margin: auto;
  height: 2px;
  background: rgb(0, 151, 238);
  margin-top: 0.3rem;
}

.aboutColumn-image {
  position: relative;
}
.aboutColumn-image img {
  border-radius: 10px 10px 0 0;
}

.about-col {
  background: rgba(0, 0, 0, 0.753);
  color: white;
  padding-bottom: 0.5rem;
  text-align: left;
  border-radius: 25px;
  box-shadow: 0px 10px 20px rgb(0, 0, 0);
  margin-bottom: 2rem;
}
.about-col h3 {
  padding-top: 3rem;
}

.about-col p {
  padding: 1rem;
}

.aboutColumn-icon {
  width: 64px;
  height: 64px;
  padding-top: 8px;
  text-align: center;
  position: absolute;
  background-color: #146180;
  border-radius: 50%;
  text-align: center;
  border: 4px solid rgba(0, 0, 0, 0.753);
  left: calc(50% - 32px);
  bottom: -30px;
  transition: 0.3s;
}

.aboutColumn-i {
  font-size: 36px;
  line-height: 1;
  color: #fff;
  transition: 0.3s;
}

.parallax-header-title::after {
  content: "";
  position: relative;
  display: block;
  width: 10rem;
  margin: auto;
  height: 3px;
  background: rgb(0, 151, 238);
}
.imageLogoLeftUp {
  width: 30vh;
  transition: 0.8s;
}

.imageLogoLeftUpsmall {
  width: 15vh;
  transition: 0.5s;
}

.paralaxcolumntitle {
  text-align: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.backgroundblackedcontainer {
  background: black;
}

.navbarontop {
  background-color: rgba(0, 0, 0, 0.76) !important;
  transition: background-color 2s ease-out;
}

.navbarnotontop {
  background-color: black !important;
  transition: background-color 2s ease-out;
}

.WhiteTextLeft {
  color: white;
  text-align: left;
}

.otherBoxes {
  background-color: #000000;
}
.Midbox {
  background-image: linear-gradient(
    0deg,
    #000000 0%,
    #252525 50%,
    #000000 100%
  );
}

.titleoffeatures {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.NavBar-Hidden {
  opacity: 0;
}

.Nav-Link {
  font-size: 1.2rem;
  margin-left: 1vw;
  color: white;
}

.carousel-caption {
  bottom: 4rem;
  z-index: 10;
  padding: 1rem;
  margin: auto;
  color: #fff;
  text-align: center;
  background-color: rgba(42, 42, 42, 0.555);
}

.Nav-Link-Contact {
  font-size: 2.5vh;
}

.Loading-Holder-Hidden {
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3d4444;
  height: 100vh;
  width: 100vw;
  transition: opacity 1s ease-in-out;
}

.Loading-Holder {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3d4444;
  height: 100vh;
  width: 100vw;
  transition: opacity 1s ease-in-out;
}

.lds-ripple-Hidden {
  opacity: 0;
  display: inline-block;
  position: relative;
  margin-left: 5vw;
  margin-top: 5vw;
  width: 40vw;
  height: 40vw;
  transition: opacity 1s ease-in-out;
}

.lds-ripple {
  opacity: 1;
  display: inline-block;
  position: relative;
  margin-left: 5vw;
  margin-top: 5vw;
  width: 40vw;
  height: 40vw;
  transition: opacity 1s ease-in-out;
}
.lds-ripple div {
  position: absolute;
  border: 1vw solid white;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1.5s cubic-bezier(0, 0.6, 0.8, 1.5) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.2s;
}
@keyframes lds-ripple {
  0% {
    top: 16vw;
    left: 16vw;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 32vw;
    height: 32vw;
    opacity: 0;
  }
}

@media screen and (min-width: 992px) {
  .sadJumbotron {
    opacity: 1;
    min-width: 88vw;
    min-height: 84vh;
    margin-bottom: 0;
  }
}

.productsSection {
  color: white;
  width: 70vw;
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 8rem;
}

.productsSection h1 {
  margin-bottom: 3rem;
}

.productsSection h1::after {
  content: "";
  position: relative;
  display: block;
  width: 16rem;
  margin: auto;
  height: 2px;
  background: rgb(0, 151, 238);
  margin-top: 0.3rem;
}
.productsSection h1 svg {
  color: rgb(0, 151, 238);
}

.productRow div {
  background-color: rgba(42, 42, 42, 0.555);
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.productRow div:hover {
  background-color: rgba(139, 139, 139, 0.287);
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.productRow div h2 {
  margin-bottom: 2rem;
}

.productDetail {
  width: 100vw;
}
.productDetail h1 {
  text-align: center;
  margin: auto;
  height: 180vh;
}

.productNavbarIconToCenter {
  margin: auto;
  justify-content: center;
}

.productNavbarLeftIconBig {
  position: absolute;
  justify-content: start;
  font-size: 1.5rem;
  margin-left: 0.5rem;
  color: rgba(255, 255, 255, 0.596);
}

.productNavbarLeftIconBig:hover {
  transition: 0.3s;
  color: rgb(255, 255, 255);
  border: 0.3rem solid white;
  font-size: 1.5rem;
  margin-left: 0;
  padding: 0.2rem;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.worldmapimageback {
  background-image: url("/images/worldmap.jpg");
}

.randommountainimageback {
  background-image: url("/images/randommountain.jpg");
}

.landimageback {
  background-image: url("/images/platform-land.jpg");
}

.airimageback {
  background-image: url("/images/platform-air.jpg");
}

.equipmentimageback {
  background-image: url("/images/platform-equipment.jpg");
}

.supportimageback {
  background-image: url("/images/platform-support.jpg");
}

.productDescription {
  margin-top: 5rem;
}

.product-col {
  background: rgba(0, 0, 0, 0.753);
  color: white;
  padding-bottom: 0.5rem;
  text-align: left;
  border-radius: 25px;
  box-shadow: 0px 10px 20px rgb(0, 0, 0);
  flex-grow: 1;
}

.product-col-p {
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
  text-align: center;
}

.videoresponsiveiframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.imageOfCarousel {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  animation: fadein 2s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.videocontainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 70%;
}

@media screen and (max-width: 500px) {
  .videocontainer {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 130.25%;
  }
}

@media screen and (max-width: 400px) {
  .videocontainer {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 200.25%;
  }
}

@media screen and (min-width: 992px) {
  .videocontainer {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 49.25%;
  }
}

@media screen and (max-width: 279px) {
  .App {
    display: none;
  }
  .notSupported {
    display: block;
    color: red;
  }
}

.carousel-control-prev {
  left: 0;
  height: 25vh;
  margin: auto;
  transition: 1s;
}

.carousel-control-next {
  right: 0;
  height: 25vh;
  margin: auto;
  transition: 1s;
}

.carousel-control-prev:hover {
  left: 0;
  height: 25vh;
  margin: auto;
  animation: hoverEffect 1s 1;
}

.carousel-control-next:hover {
  right: 0;
  height: 25vh;
  margin: auto;
  animation: hoverEffect 1s 1;
}

@keyframes hoverEffect {
  0% {
    background-color: rgb(0, 0, 0);
  }
  100% {
    background-color: none;
  }
}

.parallaxOfProduct {
  display: grid;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.doc-header-title::after {
  content: "";
  position: relative;
  display: block;
  width: 10rem;
  margin: auto;
  height: 3px;
  background: rgb(0, 151, 238);
}

.doc-header-title {
  padding-bottom: 1rem;
  padding-top: 1rem;
  text-align: center;
}

.documentCol {
  display: flex;
  align-items: center;
  overflow: auto;
}

.downloadableDocument {
  align-self: center;
  margin: 1rem;
  opacity: 0.95;
}
.downloadableDocument:hover {
  opacity: 1;
}

.downloadableDocument p {
  text-align: center;
  margin: auto;
  margin-top: 1rem;
}

.productDocsSector {
  margin: 5rem;
  margin-top: 2rem;
  margin-bottom: 0;
}
